import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import NFTDetailsReport from './NFTDetailsReport';
import copy from "copy-to-clipboard";
import { decryptData, encryptData } from '../service/enc';
const NFTReport = (props) => {

    const { id } = useParams();

    const copyaddress = (address) => {
        copy(address);
    }

    var user_name = sessionStorage.getItem("user_name");

    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);

    const [listnftsdata, setlistnftsdata] = useState(false);


    const history = useHistory();
    var user_name = sessionStorage.getItem("user_name");

    useEffect(() => {
        if (!user_name) {
            history.push('/');
        }
    }, []);

    const Getlistnftsdatabyid = async page => {
        setLoading(true);
        var datatosend = {
            method: "listnft",
            submethod: "getbyid",
            address: id,
            key: process.env.REACT_APP_KEY    
        }
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
            data: await encryptData(datatosend)
        })
            .then(res => {
                var dedata = decryptData(res.data);
                if (dedata.data) {
                    setlistnftsdata(dedata.data[0]);
                    setTotalRows(dedata.dataLength);
                }
            })
        setLoading(false);
    }
    useEffect(() => {
        Getlistnftsdatabyid();
    }, []);

    return (
        <>
            <title>NFTs Report</title>
            <div className="card">
                <div className="flex justify-content-between align-items-center mb-5">
                    <h5>NFT Details</h5>
                </div>
                <div className="grid">
                    <div className="col-12 lg:col-6 xl:col-4">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">NFT Address</span>
                                <div className="mt-1 text-600">{listnftsdata.address}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 lg:col-6 xl:col-2">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">Total Buy NFT</span>
                                <div className="mt-1 text-600">{listnftsdata.total_sell} {process.env.REACT_APP_TICKER}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 lg:col-6 xl:col-3">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">Total Staked NFT</span>
                                <div className="mt-1 text-600">{listnftsdata.total_stake} {process.env.REACT_APP_TICKER}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 lg:col-6 xl:col-3">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">Register Datetime</span>
                                <div className="mt-1 text-600">{listnftsdata.datetime}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 lg:col-6 xl:col-3">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">Total Available</span>
                                <div className="mt-1 text-600">{listnftsdata.total_items} {process.env.REACT_APP_TICKER}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mt-3">
                <div className="flex justify-content-between align-items-center mb-5">
                    <h5>Subnft List</h5>
                </div>
                <NFTDetailsReport id={id} />
            </div>
        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(NFTReport, comparisonFn);