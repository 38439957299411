// import React from 'react';
import { Link } from 'react-router-dom';
// import classNames from 'classnames';
// import { AppConfig } from './AppConfig';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { InputSwitch } from 'primereact/inputswitch';
import classNames from 'classnames';

export const AppTopbar = (props) => {


    const [switchValue, setSwitchValue] = useState(0);

    const [active, setActive] = useState(false);
    const [scale, setScale] = useState(14);
    
    const [theme, setTheme] = useState('lara-light-indigo');
    const config = useRef(null);
    let outsideClickListener = useRef(null);
    const history = useHistory();
    
    const btnLogout = () => {
        //console.log("username", user_name);
       // sessionStorage.removeItem('user_name');
        sessionStorage.clear();
        history.push('/');
    }

    const unbindOutsideClickListener = useCallback(() => {
        if (outsideClickListener.current) {
            document.removeEventListener('click', outsideClickListener.current);
            outsideClickListener.current = null;
        }
    }, []);

    const hideConfigurator = useCallback((event) => {
        setActive(false);
        unbindOutsideClickListener();
        event.preventDefault();
    }, [unbindOutsideClickListener]);

    const bindOutsideClickListener = useCallback(() => {
        if (!outsideClickListener.current) {
            outsideClickListener.current = (event) => {
                if (active && isOutsideClicked(event)) {
                    hideConfigurator(event);
                }
            };
            document.addEventListener('click', outsideClickListener.current);
        }
    }, [active, hideConfigurator]);

    useEffect(() => {
        if (active) {
            bindOutsideClickListener()
        }
        else {
            unbindOutsideClickListener()
        }
    }, [active, bindOutsideClickListener, unbindOutsideClickListener]);

    const isOutsideClicked = (event) => {
        return !(config.current.isSameNode(event.target) || config.current.contains(event.target));
    }

    const decrementScale = () => {
        setScale((prevState) => --prevState);
    }

    const incrementScale = () => {
        setScale((prevState) => ++prevState);
    }

    useEffect(() => {
        document.documentElement.style.fontSize = scale + 'px';
    }, [scale])

    const toggleConfigurator = (event) => {
        setActive(prevState => !prevState);
    }

    const configClassName = classNames('layout-config', {
        'layout-config-active': active
    })

    const replaceLink = useCallback((linkElement, href, callback) => {
        if (isIE()) {
            linkElement.setAttribute('href', href);

            if (callback) {
                callback();
            }
        }
        else {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);

                if (callback) {
                    callback();
                }
            });
        }
    }, [])

    useEffect(() => {
        let themeElement = document.getElementById('theme-link');
        const themeHref = 'assets/themes/' + theme + '/theme.css';
        replaceLink(themeElement, themeHref);

    }, [theme, replaceLink])

    const isIE = () => {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent)
    }

    const changeTheme = (e, theme, scheme) => {
        setSwitchValue(1);
        //  console.log("swithc value is",switchValue);
        props.onColorModeChange(scheme);
        setTheme(theme);
    }

    const changeTheme2 = (e, theme, scheme) => {
        setSwitchValue(0);
        //  console.log("swithc value is............2",switchValue);
        props.onColorModeChange(scheme);
        setTheme(theme);
    }

    return (
        <div className="layout-topbar">
            <Link to="/" className="layout-topbar-logo">
                <img src='assets/layout/images/aarma-logo.png' alt="paynft" />
                <span>Admin Panel</span>
            </Link>

            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars" />
            </button>

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>

            <ul className={classNames("layout-topbar-menu lg:flex origin-top", { 'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
                <li ref={config} id={"layout-config"}>
        
                    <div className="grid free-themes pd-10">
                        <div className="col-3 mdl">
                            {switchValue === 0 ? <InputSwitch onChange={(e) => changeTheme(e, 'bootstrap4-dark-blue', 'dark')} /> : <InputSwitch onChange={(e) => changeTheme2(e, 'bootstrap4-light-blue', 'light')} checked />}
                        </div>
                    </div>

                </li>
                <li>
                    <Link to='/profile' className="p-link layout-topbar-button">
                        <i className="pi pi-user" />
                        <span>Profile</span>
                    </Link>
                </li>
                <li>
                    <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                        <a href="#" onClick={btnLogout}>
                            <i className="pi pi-sign-out" />
                            <span>Logout</span>
                        </a>
                    </button>
                </li>

            </ul>
        </div>
    );
}
