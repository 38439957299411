var CryptoJS = require("crypto-js");

export  const decryptData = (data) => {
    try {
        if (!data) {
            return { error: 'no data found' }
        }
        var dedata = CryptoJS.AES.decrypt(data, process.env.REACT_APP_ENC_KEY);
        var decodedString = dedata.toString(CryptoJS.enc.Utf8);
        var reqData = JSON.parse(decodedString);
        return reqData
    } catch (error) {
        console.error(error);
    }
}

export const encryptData = async (data) => {
    try {

        if (!data) {
            return { error: 'no data found' }
        }
        var stringTosend = JSON.stringify(data);
        var reqData = CryptoJS.AES.encrypt(stringTosend, process.env.REACT_APP_ENC_KEY).toString()
        return reqData
    } catch (error) {
        console.error(error);
    }
}