import React, { useReducer, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import copy from "copy-to-clipboard";
import { Badge } from 'primereact/badge';
import { decryptData, encryptData } from '../service/enc';
const init = initialState => initialState;

//OJO: action deconstruido automaticamente en type y payload
const reducer = (state, { type, payload }) => {
    switch (type) {
        case "onPage":
            return { ...state, loading: true, first: payload.first };
        case "dataLoaded":
            return { ...state, results: payload, loading: false };
        default:
            throw new Error();
    }
};

const SubNFTReport = (props) => {

    const copyaddress = (address) => {
        copy(address);
    }

    const dt = useRef(null);

    var user_name = sessionStorage.getItem("user_name");

    const [loading123, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const [SearchInput, setSearchInput] = useState('');
    const [SearchInput1, setSearchInput1] = useState('');

    const [mainid, setmainid] = useState('');
    const [historybyid, sethistorybyid] = useState([]);

    const history = useHistory();
    var user_name = sessionStorage.getItem("user_name");

    useEffect(() => {
        if (!user_name) {
            history.push('/');
        }
    }, [props]);

    const initialState = {
        results: [],
        loading: false,
        first: 0,
        rows: 10,
        totalRecords: totalRows
    };
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const { results, loading, rows, first, totalRecords } = state;

    //pagination

    useEffect((id) => {

        SearchHistoryByID(id);
        GetHistoryByID(id);

    }, [props, loading, first, rows]);

    const SearchHistoryByID = async (m_id, page) => {

        var datatosend = {
            method: "history",
            submethod: "searchbyid",
            mainnft_id: props.m_id,
            input: SearchInput1,
            key: process.env.REACT_APP_KEY,
            page: page, per_page: perPage
        }
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
            data: await encryptData(datatosend)
        })
            .then(res => {
                var dedata = decryptData(res.data);
                if (dedata.data) {
                    sethistorybyid(dedata.data);
                }
            })
    }
    const GetHistoryByID = async (m_id, page) => {
        setLoading(true);
        var datatosend = {
            method: "history",
            submethod: "getbyid",
            mainnft_id: props.m_id,
            key: process.env.REACT_APP_KEY,
            page: (first + rows) / 10,
            per_page: perPage
        }
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
            data: await encryptData(datatosend)

        })
            .then(res => {
                var dedata = decryptData(res.data);
                if (dedata.data) {
                    sethistorybyid(dedata.data);
                    setTotalRows(dedata.dataLength);
                }
            })
        setLoading(false);
    }

    const userBody = (rowData) => {
        return (
            <>
                <b><i class="pi pi-send" aria-hidden="true"></i> </b>
                <span><a href={`${process.env.REACT_APP_TAD_URL}${rowData.user_address}`} target="_blank">{rowData.user_address ? rowData.user_address.substr(0, 5) + "..." + rowData.user_address.substr(-5, 5) : ''}</a> </span><span onClick={(e) => copyaddress(rowData.user_address)} id={rowData.user_address}><i className='pi pi-copy' /></span>
                <br />
            </>
        );
    }

    const statusBody = (rowData) => {
        return (
            <>
                <div className="actions">
                    {rowData.status === 0 ? <Badge value="No" className="mr-3 mt-2" severity="danger"></Badge> : ''}

                    {rowData.status === 1 ? <Badge value="Buy" className="mr-3 mt-2" severity="success"></Badge> : ''}

                    {rowData.status === 2 ? <Badge value="Staked" className="mr-3 mt-2" severity="warning"></Badge> : ''}
                </div>
            </>
        );
    }
    const rateHistoryBody = (rowData) => {
        return (
            <>
                <span>
                    ${rowData.rate_d}
                </span> <br />
                <span>
                    {rowData.rate_arma} {process.env.REACT_APP_TICKER}
                </span>
            </>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <div className="col-12 lg:col-6 xl:col-7"></div>
            <div className="col-12 lg:col-6 xl:col-5">
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    {/* <i className="pi pi-search" onClick={() => SearchHistoryByID(mainid)} />
                    <InputText type="Search History" onChange={(e) => setSearchInput1(e.target.value)} placeholder="Search Here..." /> */}

                    <i className="pi pi-search" onClick={SearchHistoryByID(mainid)} />
                    <InputText type="Search" onChange={(e) => setSearchInput1(e.target.value)} placeholder="Search..." />
                </span>
            </div>
        </div>
    );


    return (

        <>

            <DataTable ref={dt} value={historybyid} dataKey="id" paginator rows={10} className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of History"
                SearchInput={SearchInput} emptyMessage="No data found." header={header} responsiveLayout="scroll"
                totalRecords={totalRows} lazy first={first} onPage={e => dispatch({ type: "onPage", payload: e })}
            >

                <Column field="id" header="Sr." sortable body={historybyid.id} headerStyle={{ width: '7%', minWidth: '7rem' }}></Column>
                <Column field="user" header="User" sortable body={userBody} headerStyle={{ width: '15%', minWidth: '15rem' }}></Column>
                <Column field="status" header="Status" sortable body={statusBody} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                <Column field="rate" header="Rate" sortable body={rateHistoryBody} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                <Column field="datetime" header="Created Date" sortable body={historybyid.datetime} headerStyle={{ width: '15%', minWidth: '15rem' }}></Column>

            </DataTable>

        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default SubNFTReport;
