import React, { useReducer, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import axios from 'axios';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { decryptData, encryptData } from '../service/enc';
import copy from "copy-to-clipboard";

const init = initialState => initialState;

//OJO: action deconstruido automaticamente en type y payload
const reducer = (state, { type, payload }) => {
    switch (type) {
        case "onPage":
            return { ...state, loading: true, first: payload.first };
        case "dataLoaded":
            return { ...state, results: payload, loading: false };
        default:
            throw new Error();
    }
};

const Users = (props) => {
    const copyaddress = (address) => {
        copy(address);
    }

    const [usersdata, setusersdata] = useState([]);
    const [loading1, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [SearchInput, setSearchInput] = useState('');

    const dt = useRef(null);

    const history = useHistory();
    var user_name = sessionStorage.getItem("user_name");

    useEffect(() => {
        if (!user_name) {
            history.push('/');
        }
    }, []);

    const initialState = {
        results: [],
        loading: false,
        first: 0,
        rows: 10,
        totalRecords: totalRows
    };
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const { results, loading, rows, first, totalRecords } = state;

    const SearchUsersListdata = async () => {
        // console.log("Search UsersList Data Input", SearchInput);
        var datatosend = {
            method: "user",
            submethod: "search",
            input: SearchInput,
            key: process.env.REACT_APP_KEY
        }
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
            data: await encryptData(datatosend)
        })
            .then(res => {
                var dedata = decryptData(res.data);

                if (dedata.data) {
                    setusersdata(dedata.data);
                }
            })
    }
    const Getusersdata = async page => {
        setLoading(true);
        var datatosend = {
            method: "user",
            submethod: "get",
            key: process.env.REACT_APP_KEY,
            page: (first + rows) / 10,
            per_page: perPage
            
        }
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
            data: await encryptData(datatosend)

        })
            .then(res => {
                var dedata = decryptData(res.data);

                if (dedata.data) {
                    setusersdata(dedata.data);
                    // console.log("dedata.dataLengthdedata.dataLength",dedata.data);
                    setTotalRows(dedata.dataLength);
                }
            })
        setLoading(false);
    }

    //pagination

    useEffect(() => {
        if (SearchInput !== '') {
            SearchUsersListdata();
        }
        else {
            console.log("loading, first, rows",loading, first, rows);
            Getusersdata();
        }
    }, [loading, first, rows]);


    const userAddressBody = (rowData) => {
        return (
            <>
                <b><i class="pi pi-send" aria-hidden="true"></i> </b>
                <span><a href={`${process.env.REACT_APP_TAD_URL}${rowData.address}`} target="_blank">{rowData.address.substr(0, 5) + "..." + rowData.address.substr(-5, 5)}</a> </span><span onClick={(e) => copyaddress(rowData.address)} id={rowData.address}><i className='pi pi-copy' /></span>
                <br />
            </>
        );
    }
    const buynftBody = (rowData) => {
        return (
            <>
                <span>
                    {rowData.buy_nft} {process.env.REACT_APP_TICKER}
                </span>
            </>
        );
    }

    const stakednftBody = (rowData) => {
        return (
            <>
                <span>
                    {rowData.staked_nft} {process.env.REACT_APP_TICKER}
                </span>
            </>
        );
    }

    const dateBody = (rowData) => {
        return (
            <>
                <span>
                    {rowData.datetime}
                </span>
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <a type='button' className='button-success bg-success user_report' href="javascript:void(0);" onClick={() => history.push(`/userreport/${rowData.address}`, { state: { address: rowData.address } })}>Report</a>
                </div>
            </>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Users List</h5>

            <span className="block mt-2 md:mt-0 p-input-icon-left">

                <div class="p-inputgroup">

                    <input placeholder="Search..." onChange={(e) => setSearchInput(e.target.value)} class="p-inputtext p-component" />

                    {SearchInput === '' ?

                        <button class="p-button p-component"><span class="p-button-label p-c" onClick={() => Getusersdata()}>Search</span><span class="p-ink"></span></button>

                        :

                        <button class="p-button p-component"><span class="p-button-label p-c" onClick={() => SearchUsersListdata(currentPage)}>Search</span><span class="p-ink"></span></button>

                    }

                </div>

            </span>
        </div>
    );

    return (

        <>

            <title>Users</title>

            <div className="grid WalletUsersList-demo">
                <div className="col-12">
                    <div className="card">

                        <DataTable ref={dt} value={usersdata} dataKey="id" paginator rows={10} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of Users"
                            SearchInput={SearchInput} emptyMessage="No data found." header={header} responsiveLayout="scroll"
                            totalRecords={totalRows} lazy first={first} onPage={e => dispatch({ type: "onPage", payload: e })}
                        >
                            <Column field="id" header="Sr." sortable body={usersdata.id} headerStyle={{ width: '7%', minWidth: '7rem' }}></Column>
                            <Column field="user" header="User" sortable body={userAddressBody} headerStyle={{ width: '12%', minWidth: '12rem' }}></Column>
                            <Column field="buynft" header="Buy NFT" sortable body={buynftBody} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                            <Column field="stakednft" header="Staked NFT" sortable body={stakednftBody} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                            <Column field="date" header="Datetime" sortable body={dateBody} headerStyle={{ width: '12%', minWidth: '12rem' }}></Column>
                            <Column field="action" header="Action" body={actionBodyTemplate} headerStyle={{ width: '8%', minWidth: '8rem' }}></Column>
                        </DataTable>

                    </div>
                </div>
            </div>

        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Users, comparisonFn);