import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProductService } from '../service/ProductService';
import Web3 from 'web3';
import { decryptData, encryptData } from '../service/enc';
const Dashboard = (props) => {

    const [products, setProducts] = useState(null);

    const [products1, setProducts1] = useState(null);
    const [products2, setProducts2] = useState(null);


    const [lineOptions, setLineOptions] = useState(null)

    const productService = new ProductService();
    const history = useHistory();
    var user_name = sessionStorage.getItem("user_name");

    useEffect(() => {
        if (!user_name) {
            history.push('/');
        }
    }, []);

    const [loading, setLoading] = useState(false);
    const [totalRowsnft, settotalRowsnft] = useState(0);
    const [totalsoldRows, settotalsoldRows] = useState(0);
    const [totalstakeRows, settotalstakeRows] = useState(0);
    const [totalavailableRows, settotalavailableRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [setting, setsetting] = useState([]);
    const [ddata, setddata] = useState([]);


    const GetListNFTData = async page => {
        setLoading(true);
        var datatosend = {
            method: "listnft",
            submethod: "get",
            key: process.env.REACT_APP_KEY,
            page: page, per_page: perPage
        }
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
            data: await encryptData(datatosend)
        })
            .then(res => {
                var dedata = decryptData(res.data);
                if (dedata.data) {
                    settotalRowsnft(dedata.totalnfts);
                    settotalsoldRows(dedata.totalsold);
                    settotalstakeRows(dedata.totalstake);
                    settotalavailableRows(dedata.totalavailable);
                }
            })
        setLoading(false);
    }
    const getData = async () => {
        if (!setting) {
            return
        }
        const web3 = new Web3(Web3.givenProvider || process.env.REACT_APP_RPC)
        var contract = new web3.eth.Contract(JSON.parse(setting.busd_token_abi), setting.busd_token_address);
        var contract1 = new web3.eth.Contract(JSON.parse(setting.token_abi), setting.token_address);
        var getowb = await web3.eth.getBalance(process.env.REACT_APP_OWNER_ADDRESS);
        var data = await contract.methods.balanceOf(setting.busd_smart_contract).call();
        var data1 = await contract1.methods.balanceOf(setting.busd_smart_contract).call();

        setddata({
            obnb: Number(web3.utils.fromWei(getowb, 'ether')).toFixed(4),
            bsbusd: Number(web3.utils.fromWei(data, 'ether')).toFixed(4),
            bstkn: Number(web3.utils.fromWei(data1, 'ether')).toFixed(4),

        })
        console.log("gettwt", web3.utils.fromWei(getowb, 'ether'));

    }
    useEffect(() => {
        GetListNFTData();
    }, []);

    const applyLightTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef',
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef',
                    }
                },
            }
        };

        setLineOptions(lineOptions)
    }

    const applyDarkTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#ebedef'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#ebedef'
                    },
                    grid: {
                        color: 'rgba(160, 167, 181, .3)',
                    }
                },
                y: {
                    ticks: {
                        color: '#ebedef'
                    },
                    grid: {
                        color: 'rgba(160, 167, 181, .3)',
                    }
                },
            }
        };

        setLineOptions(lineOptions)
    }
    const getSetting = async () => {
        var datatosend = {
            method: "setting",
            submethod: "get",
            key: process.env.REACT_APP_KEY,
        };

        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
            data: await encryptData(datatosend)
        }).then(res => {
            var dedata = decryptData(res.data);
            if (dedata.error) {
                console.error(dedata);
                return ''
            }
            setsetting(dedata.data)
        })
    }
    useEffect(() => {
        productService.getProductsSmall().then(data => setProducts(data));
        productService.getProductsSmallOne().then(data => setProducts1(data));
        productService.getProductsSmallTwo().then(data => setProducts2(data));

        getSetting()
    }, []);
    useEffect(() => {
        getData()
    }, [setting])
    useEffect(() => {
        if (props.colorMode === 'light') {
            applyLightTheme();
        } else {
            applyDarkTheme();
        }
    }, [props.colorMode]);

    /** New Js Added **/

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.id}
            </>
        );
    }
    const codeBodyTemplatePackage = (rowDataPackage) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowDataPackage.codePackage}
            </>
        );
    }

    const codeBodyTemplatePackageOne = (rowDataPackageOne) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowDataPackageOne.namePackageOne}
            </>
        );



    }

    const codeBodyTemplatePackageTwo = (rowDataPackageTwo) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowDataPackageTwo.namePackageTwo}
            </>
        );
    }

    const codeBodyTemplatePackageThree = (rowDataPackageThree) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowDataPackageThree.namePackageThree}
            </>
        );
    }
    return (
        <>

            <title>Dashboard</title>

            <div className="grid">

                <div className="col-12 lg:col-6 xl:col-3">
                    <div className="card mb-0">
                        <div className="flex justify-content-between mb-0">
                            <div className='mb-5'>
                                <span className="block text-500 font-medium mb-3">TOTAL NFTS</span>
                                <div className="text-900 font-medium text-xl mb-1 mt-1">
                                    {totalRowsnft ? totalRowsnft : 0} {process.env.REACT_APP_TICKER}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 lg:col-6 xl:col-3">
                    <div className="card mb-0">
                        <div className="flex justify-content-between mb-0">
                            <div className='mb-5'>
                                <span className="block text-500 font-medium mb-3">TOTAL SOLD</span>
                                <div className="text-900 font-medium text-xl mb-1 mt-1">
                                    {totalsoldRows ? totalsoldRows : 0} {process.env.REACT_APP_TICKER}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 lg:col-6 xl:col-3">
                    <div className="card mb-0">
                        <div className="flex justify-content-between mb-0">
                            <div className='mb-5'>
                                <span className="block text-500 font-medium mb-3">TOTAL STAKED</span>
                                <div className="text-900 font-medium text-xl mb-1 mt-1">
                                    {totalstakeRows ? totalstakeRows : 0} {process.env.REACT_APP_TICKER}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 lg:col-6 xl:col-3">
                    <div className="card mb-0">
                        <div className="flex justify-content-between mb-0">
                            <div className='mb-5'>
                                <span className="block text-500 font-medium mb-3">TOTAL AVAILABLE</span>
                                <div className="text-900 font-medium text-xl mb-1 mt-1">
                                    {totalavailableRows ? totalavailableRows : 0} {process.env.REACT_APP_TICKER}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 lg:col-6 xl:col-4">
                    <div className="card mb-0">
                        <div className="flex justify-content-between mb-0">
                            <div className='mb-5'>
                                <span className="block text-500 font-medium mb-3">Owner Address</span>
                                <div className="text-900 font-medium text-xl mb-1 mt-1 text-break">
                                    {process.env.REACT_APP_OWNER_ADDRESS}
                                    <br />
                                    {ddata.obnb ? ddata.obnb : '0'} BNB
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 lg:col-6 xl:col-4">
                    <div className="card mb-0">
                        <div className="flex justify-content-between mb-0">
                            <div className='mb-5'>
                                <span className="block text-500 font-medium mb-3">BUSD Contract</span>
                                <div className="text-900 font-medium text-xl mb-1 mt-1 text-break">
                                    {setting ? setting.busd_smart_contract : '0'}
                                    <br />
                                    {ddata.bstkn ? ddata.bstkn : '0'} AARMA
                                    <br/>
                                    {ddata.bsbusd ? ddata.bsbusd : '0'} BUSD
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 xl:col-12">
                    <div className="card">
                        {/* <h5>Recent Sales</h5> */}
                        <h5 className='mt-2 mb-5'>Package Details</h5>

                        <DataTable value={products1} rows={5} paginator responsiveLayout="scroll">
                            <Column field="id" header="Sr." sortable body={codeBodyTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                            <Column field="name" header="Name" sortable body={codeBodyTemplatePackage} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                            <Column field="price" header="Reward" sortable body={codeBodyTemplatePackageOne} headerStyle={{ width: '15%', minWidth: '10rem' }}></Column>
                            <Column field="price" header="Total Staked" sortable body={codeBodyTemplatePackageTwo} headerStyle={{ width: '15%', minWidth: '10rem' }}></Column>
                            <Column field="price" header="Total Holders" sortable body={codeBodyTemplatePackageThree} headerStyle={{ width: '15%', minWidth: '10rem' }}></Column>
                            <Column header="" style={{ width: '10%' }} body={() => (
                                <>
                                    <a type='button' className='button-success bg-success user_report' href="/#/PkgReport">Report</a>
                                </>
                            )} />
                        </DataTable>
                    </div>

                    {/* <div className="card">
                        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center mt-2 mb-5">
                            <h5 className="m-0">Pending Withdrawal</h5>
                            <span className="block mt-2 md:mt-0 p-input-icon-left">
                                <i className="pi pi-search" />
                                <InputText type="search" placeholder="Search..." />
                            </span>
                        </div>
                        <DataTable value={products2} rows={5} paginator responsiveLayout="scroll">
                            <Column field="id" header="Sr." sortable body={codeBodyTemplatePendingNo} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                            <Column field="name" header="Address" sortable body={codeBodyTemplatePending} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                            <Column field="price" header="Amount" sortable body={codeBodyTemplatePendingOne} headerStyle={{ width: '15%', minWidth: '10rem' }}></Column>
                            <Column field="price" header="Date" sortable body={codeBodyTemplatePendingTwo} headerStyle={{ width: '15%', minWidth: '10rem' }}></Column>
                            <Column header="Status" style={{ width: '15%' }} body={() => (
                                <>
                                    <Button icon="pi pi-check" className="p-button-rounded  mr-2 mb-2" />
                                    <Button icon="pi pi-times" className="p-button-rounded p-button-danger  mr-2 mb-2" />
                                </>
                            )}
                            />
                        </DataTable>
                    </div> */}
                </div>
            </div>
        </>

    );
}

const comparisonFn = function (prevProps, nextProps) {
    return (prevProps.location.pathname === nextProps.location.pathname) && (prevProps.colorMode === nextProps.colorMode);
};

export default React.memo(Dashboard, comparisonFn);
