import React, { useReducer, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import copy from "copy-to-clipboard";
import { Badge } from 'primereact/badge';
import { Dialog } from 'primereact/dialog';
import SubNFTReport from './SubNFTReport';
import { decryptData, encryptData } from '../service/enc';
const init = initialState => initialState;

//OJO: action deconstruido automaticamente en type y payload
const reducer = (state, { type, payload }) => {
    switch (type) {
        case "onPage":
            return { ...state, loading: true, first: payload.first };
        case "dataLoaded":
            return { ...state, results: payload, loading: false };
        default:
            throw new Error();
    }
};

const NFTDetailsReport = (props) => {

    const copyaddress = (address) => {
        copy(address);
    }
    const dt = useRef(null);

    var user_name = sessionStorage.getItem("user_name");

    const [subnftdetailslist, setsubnftdetailslist] = useState([]);

    const [loading1, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const [SearchInput, setSearchInput] = useState('');
    const [SearchInput1, setSearchInput1] = useState('');

    const [mainid, setmainid] = useState('');

    const [HistoryDialog, setHistoryDialog] = useState(false);

    const [historybyid, sethistorybyid] = useState([]);
    const [historyrow, sethistoryrow] = useState([]);

    const history = useHistory();
    var user_name = sessionStorage.getItem("user_name");

    useEffect(() => {
        if (!user_name) {
            history.push('/');
        }
    }, []);

    const initialState = {
        results: [],
        loading: false,
        first: 0,
        rows: 10,
        totalRecords: totalRows
    };
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const { results, loading, rows, first, totalRecords } = state;

    const SearchSubNFTDetailsReportListdata = async () => {
        var datatosend = {
            method: "subnft",
            submethod: "searchbyid",
            nft_address: props.id,
            input: SearchInput,
            key: process.env.REACT_APP_KEY
        }
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
            data: await encryptData(datatosend)
        })
            .then(res => {
                var dedata = decryptData(res.data);
                if (dedata.data) {
                    setsubnftdetailslist(dedata.data);
                }
            })
    }
    const Getsubnftdetailslist = async page => {
        // console.log("propsidddddd",props.id);
        setLoading(true);
        var datatosend = {
            method: "subnft",
            submethod: "getbyid",
            nft_address: props.id,
            key: process.env.REACT_APP_KEY,
            page: (first + rows) / 10,
            per_page: perPage
        }
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
            data: await encryptData(datatosend)
        })
            .then(res => {
                var dedata = decryptData(res.data);
                if (dedata.data) {
                    setsubnftdetailslist(dedata.data);
                    setTotalRows(dedata.dataLength);
                }
            })
        setLoading(false);
    }

    //pagination

    useEffect(() => {
        if (SearchInput !== '') {
            SearchSubNFTDetailsReportListdata();
        }
        else {
            Getsubnftdetailslist();
        }

    }, [loading, first, rows]);


    const nftDetailsBody = (rowData) => {
        return (
            <>
                <img src={`assets/images/listnfts/${rowData.nft_img}`} alt={rowData.nft_img} className="shadow-2" width={100} /> <br /> <br />

                {/* <span>{rowData._id}</span> */}
                <b><i class="pi pi-send" aria-hidden="true"></i> </b>
                <span><a href={`${process.env.REACT_APP_TAD_URL}${rowData.nft_address}`} target="_blank">{rowData.nft_address.substr(0, 5) + "..." + rowData.nft_address.substr(-5, 5)}</a> </span><span onClick={(e) => copyaddress(rowData.nft_address)} id={rowData.nft_address}><i className='pi pi-copy' /></span>

            </>
        );
    }
    const rateBody = (rowData) => {
        return (
            <>
                <span>
                    ${rowData.rate_d}
                </span> <br />
                <span>
                    {rowData.rate_arma} {process.env.REACT_APP_TICKER}
                </span>
            </>
        );
    }
    const userdetailsBody = (rowData) => {
        return (
            <>
                <b><i class="pi pi-send" aria-hidden="true"></i> </b>
                <span><a href={`${process.env.REACT_APP_TAD_URL}${rowData.user_address}`} target="_blank">{rowData.user_address.substr(0, 5) + "..." + rowData.user_address.substr(-5, 5)}</a> </span><span onClick={(e) => copyaddress(rowData.user_address)} id={rowData.user_address}><i className='pi pi-copy' /></span>
                <br />
            </>
        );
    }

    const dateBody = (rowData) => {
        return (
            <>
                <span>
                    {rowData.datetime}
                </span>
            </>
        );
    }

    const userstatusBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.status === 0 ? <Badge value="Not assigned" className="mr-3 mt-2" severity="warning"></Badge> : ''}

                {rowData.status === 1 ? <Badge value="Assigned" className="mr-3 mt-2" severity="success"></Badge> : ''}

            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <a type='button' className='button-success bg-success user_report' href="javascript:void(0);" onClick={(e) => openNew(rowData.m_id)} >History</a>
                </div>
            </>
        );
    }

    const openNew = (id) => {

        console.log("thiisisisisisi is=>", id);
        setmainid(id);
        SearchHistoryByID(id);
        GetHistoryByID(id);
        console.log("rowData._id is=>", id);
        setHistoryDialog(true);
    }

    const hideDialog = () => {
        setHistoryDialog(false);
    }

    const SearchHistoryByID = async (id, page) => {
        // console.log("search mainid isssssssssssss", id);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "history", submethod: "searchbyid", mainnft_id: id, input: SearchInput1, key: process.env.REACT_APP_KEY, page: page, per_page: perPage })
            .then(res => {
                console.log("Search History By ID Data is", res.data.data);
                if (res.data.data) {
                    sethistorybyid(res.data.data);
                }
            })
    }
    const GetHistoryByID = async (id, page) => {
        setLoading(true);
        // console.log("idddd............", id);
        var datatosend = {
            method: "history",
            submethod: "getbyid",
            mainnft_id: id,
            key: process.env.REACT_APP_KEY,
            page: page, per_page: perPage    
        }
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
            data: await encryptData(datatosend)
        })
            .then(res => {
                var dedata = decryptData(res.data);
                if (dedata.data) {
                    sethistorybyid(dedata.data);
                    sethistoryrow(dedata.data[0]);
                    setTotalRows(dedata.dataLength);
                }
            })
        setLoading(false);
    }

    const userBody = (rowData) => {
        return (
            <>
                <b><i class="pi pi-send" aria-hidden="true"></i> </b>
                <span><a href={`${process.env.REACT_APP_TAD_URL}${rowData.user_address}`} target="_blank">{rowData.user_address.substr(0, 5) + "..." + rowData.user_address.substr(-5, 5)}</a> </span><span onClick={(e) => copyaddress(rowData.user_address)} id={rowData.user_address}><i className='pi pi-copy' /></span>
                <br />
            </>
        );
    }

    const statusBody = (rowData) => {
        return (
            <>
                <div className="actions">
                    {rowData.status === 0 ? <Badge value="No" className="mr-3 mt-2" severity="danger"></Badge> : ''}

                    {rowData.status === 1 ? <Badge value="Buy" className="mr-3 mt-2" severity="success"></Badge> : ''}

                    {rowData.status === 2 ? <Badge value="Staked" className="mr-3 mt-2" severity="warning"></Badge> : ''}
                </div>
            </>
        );
    }
    const rateHistoryBody = (rowData) => {
        return (
            <>
                <span>
                    ${rowData.rate_d}
                </span> <br />
                <span>
                    {rowData.rate_arma} {process.env.REACT_APP_TICKER}
                </span>
            </>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <div className="col-12 lg:col-6 xl:col-7"></div>
            <div className="col-12 lg:col-6 xl:col-5">
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    {/* <i className="pi pi-search" onClick={() => SearchSubNFTDetailsReportListdata(currentPage)} />
                    <InputText type="Search" onChange={(e) => setSearchInput(e.target.value)} placeholder="Search..." /> */}

                    <i className="pi pi-search" onClick={SearchSubNFTDetailsReportListdata()} />
                    <InputText type="Search" onChange={(e) => setSearchInput(e.target.value)} placeholder="Search..." />
                </span>
            </div>
        </div>
    );

    return (

        <>

            <DataTable ref={dt} value={subnftdetailslist} dataKey="id" paginator rows={10} className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of Nfts Details"
                SearchInput={SearchInput} emptyMessage="No data found." header={header} responsiveLayout="scroll"
                totalRecords={totalRows} lazy first={first} onPage={e => dispatch({ type: "onPage", payload: e })}
            >

                <Column field="id" header="Sr." sortable body={subnftdetailslist.id} headerStyle={{ width: '7%', minWidth: '7rem' }}></Column>
                <Column field="nftdetails" header="Nft Details" sortable body={nftDetailsBody} headerStyle={{ width: '15%', minWidth: '15rem' }}></Column>
                <Column field="rate" header="Rate" sortable body={rateBody} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                <Column field="user" header="User Details" sortable body={userdetailsBody} headerStyle={{ width: '13%', minWidth: '13rem' }}></Column>
                <Column field="status" header="Status" sortable body={userstatusBodyTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                <Column field="createddate" header="Created Date" sortable body={dateBody} headerStyle={{ width: '13%', minWidth: '13rem' }}></Column>
                <Column field="" header="Action" body={actionBodyTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>

            </DataTable>

            <Dialog visible={HistoryDialog} header="History" modal className="p-fluid" onHide={hideDialog}>

                <SubNFTReport m_id={historyrow.mainnft_id} />

            </Dialog>


        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default NFTDetailsReport;