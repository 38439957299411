import axios from 'axios';

export class ProductService {

    getProductsSmall() {
        return axios.get('assets/demo/data/products-small.json').then(res => res.data.data);
    }

    getProductsSmallOne() {
        return axios.get('assets/demo/data/products-small-one.json').then(res => res.data.data);
    }

    getProductsSmallTwo() {
        return axios.get('assets/demo/data/products-small-two.json').then(res => res.data.data);
    }


    getProducts() {
        return axios.get('assets/demo/data/products.json').then(res => res.data.data);
    }

    getProductsWithOrdersSmall() {
        return axios.get('assets/demo/data/products-orders-small.json').then(res => res.data.data);
    }
    
    // getTreeTableNodes() {
    //     return axios.get('assets/demo/data/treetablenodes.json').then(res => res.data.data);
    // }
}