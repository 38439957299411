import React, { useReducer, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import carsfile from "./cars-small.json";

import axios from 'axios';

const init = initialState => initialState;

//OJO: action deconstruido automaticamente en type y payload
const reducer = (state, { type, payload }) => {
    switch (type) {
        case "onPage":
            return { ...state, loading: true, first: payload.first };
        case "dataLoaded":
            return { ...state, results: payload, loading: false };
        default:
            throw new Error();
    }
};

const Test = (props) => {

    const [usersdata, setusersdata] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [loading1, setLoading] = useState(false);

    const initialState = {
        results: [],
        loading: false,
        first: 0,
        rows: 10,
        totalRecords: totalRows
    };
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const { results, loading, rows, first, totalRecords } = state;

    const Getusersdata = async page => {
      //  setLoading(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "users", submethod: "get", key: process.env.REACT_APP_KEY, page: (first + rows) / 10, per_page: 10 })
            .then(res => {
                console.log("UsersList Data is", res.data.data);
                console.log("UsersList DataLength is", res.data.dataLength);
                if (res.data.data) {
                    setusersdata(res.data.data);
                    setTotalRows(res.data.dataLength);
                }
            })
        setLoading(false);
    }


    useEffect(() => {
        Getusersdata();
        // if (loading) {
        //     setTimeout(() => {
                const startIndex = first;
                const endIndex = first + rows;

                console.log("startIndex ist", startIndex, rows, state);

                console.log("endIndex ist", endIndex / 10);
                
                // const data = carsfile.data.slice(startIndex, endIndex);

                // const data = usersdata.data.slice(startIndex, endIndex);

                // dispatch({ type: "dataLoaded", payload: data });
                
        //     }, 50);
           
        // }
       
    }, [loading, first, rows]);

    return (
        <div>
            <DataTable
                value={usersdata}
                paginator
                rows={rows}
                totalRecords={totalRows}
                lazy
                first={first}
                onPage={e => dispatch({ type: "onPage", payload: e })}
               
            >

                <Column field="datetime" header="Sr." sortable body={usersdata.datetime} headerStyle={{ width: '7%', minWidth: '7rem' }}></Column>

            </DataTable>
        </div>
    );
};


const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Test, comparisonFn);