import React, { useReducer, useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';

import axios from 'axios';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Badge } from 'primereact/badge';

import copy from "copy-to-clipboard";
import { decryptData, encryptData } from '../service/enc';
const init = initialState => initialState;

//OJO: action deconstruido automaticamente en type y payload
const reducer = (state, { type, payload }) => {
    switch (type) {
        case "onPage":
            return { ...state, loading: true, first: payload.first };
        case "dataLoaded":
            return { ...state, results: payload, loading: false };
        default:
            throw new Error();
    }
};

const StakedNfts = (props) => {

    const copyaddress = (address) => {
        copy(address);
    }

    const [stakednftsdata, setstakednftsdata] = useState([]);
    const [loading1, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [SearchInput, setSearchInput] = useState('');

    const dt = useRef(null);

    const history = useHistory();
    var user_name = sessionStorage.getItem("user_name");

    useEffect(() => {
        if (!user_name) {
            history.push('/');
        }
    }, []);

    const initialState = {
        results: [],
        loading: false,
        first: 0,
        rows: 10,
        totalRecords: totalRows
    };
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const { results, loading, rows, first, totalRecords } = state;

    const SearchStakedNftsListdata = async () => {
        // console.log("Search StakedNftsList Data Input", SearchInput);
        var datatosend = {
            method: "stakednfts",
            submethod: "search",
            input: SearchInput,
            key: process.env.REACT_APP_KEY,
            page: (first + rows) / 10,
            per_page: perPage

        }
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
            data: await encryptData(datatosend)
        })
            .then(res => {
                var dedata = decryptData(res.data);
                if (dedata.data) {
                    setstakednftsdata(dedata.data);
                    setTotalRows(dedata.dataLength);

                }
            })
    }
    const Getstakednftsdata = async page => {
        setLoading(true);
        var datatosend = {
            method: "stakednfts",
            submethod: "get",
            key: process.env.REACT_APP_KEY,
            page: (first + rows) / 10,
            per_page: perPage    
        }
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
            data: await encryptData(datatosend)
        })
            .then(res => {
                var dedata = decryptData(res.data);
                if (dedata.data) {
                    setstakednftsdata(dedata.data);
                    setTotalRows(dedata.dataLength);
                }
            })
        setLoading(false);
    }

    //pagination

    useEffect(() => {
        if (SearchInput !== '') {
            SearchStakedNftsListdata();
        }
        else {
            Getstakednftsdata();
        }

    }, [loading, first, rows]);


    const userAddressBody = (rowData) => {
        return (
            <>
                <b># </b>
                <span><a href={`${process.env.REACT_APP_TXN_URL}${rowData.hash}`} target="_blank">{rowData.hash.substr(0, 5) + "..." + rowData.hash.substr(-5, 5)}</a> </span>
                <span onClick={(e) => copyaddress(rowData.hash)} id={rowData.hash}><i className='pi pi-copy' /></span>
                <br />

                <b><i class="pi pi-send" aria-hidden="true"></i> </b>
                <span><a href={`${process.env.REACT_APP_TAD_URL}${rowData.user_address}`} target="_blank">{rowData.user_address.substr(0, 5) + "..." + rowData.user_address.substr(-5, 5)}</a> </span><span onClick={(e) => copyaddress(rowData.user_address)} id={rowData.user_address}><i className='pi pi-copy' /></span>
                <br />
            </>
        );
    }
    const nftDetailsBody = (rowData) => {
        return (
            <>
                <img src={`assets/images/listnfts/${rowData.stakednftimg}`} alt={rowData.stakednftimg} className="shadow-2" width={100} /> <br /> <br />
                <b><i class="pi pi-send" aria-hidden="true"></i> </b>
                <span><a href={`${process.env.REACT_APP_TAD_URL}${rowData.address}`} target="_blank">{rowData.address.substr(0, 5) + "..." + rowData.address.substr(-5, 5)}</a> </span><span onClick={(e) => copyaddress(rowData.address)} id={rowData.address}><i className='pi pi-copy' /></span>
                <br />
                <span>UserId : {rowData.api_user_id}</span>
                <br />
                <span>NFTId : {rowData.nft_id}</span>
                <br />
            </>
        );
    }
    const rateBody = (rowData) => {
        return (
            <>
                <span>
                    ${rowData.amount}
                </span>  <br />
                <span>
                    {rowData.arma_rate} {process.env.REACT_APP_TICKER}
                </span>
            </>
        );
    }
    const stakedBody = (rowData) => {
        return (
            <>
                <span>
                    {rowData.staked_arma} {process.env.REACT_APP_TICKER}
                </span>
            </>
        );
    }

    const dateBody = (rowData) => {
        return (
            <>
                <span>
                    {rowData.datetime}
                </span>
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    {rowData.status === 0 ? <Badge value="Unstaked" className="mr-3 mt-2" severity="warning"></Badge> : ''}

                    {rowData.status === 1 ? <Badge value="Staked" className="mr-3 mt-2" severity="success"></Badge> : ''}
                </div>
            </>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Staked NFT's List</h5>

            <span className="block mt-2 md:mt-0 p-input-icon-left">

                <div class="p-inputgroup">

                    <input placeholder="Search..." onChange={(e) => setSearchInput(e.target.value)} class="p-inputtext p-component" />

                    {!SearchInput ?

                        <button class="p-button p-component"><span class="p-button-label p-c" onClick={() => Getstakednftsdata()}>Search</span><span class="p-ink"></span></button>

                        :

                        <button class="p-button p-component"><span class="p-button-label p-c" onClick={() => SearchStakedNftsListdata(currentPage)}>Search</span><span class="p-ink"></span></button>

                    }

                </div>

            </span>

        </div>
    );

    return (

        <>

            <title>StakedNFTs</title>

            <div className="grid NodesList-demo">
                <div className="col-12">
                    <div className="card">

                        <DataTable ref={dt} value={stakednftsdata} dataKey="id" paginator rows={10} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of StakedNfts"
                            SearchInput={SearchInput} emptyMessage="No data found." header={header} responsiveLayout="scroll"
                            totalRecords={totalRows} lazy first={first} onPage={e => dispatch({ type: "onPage", payload: e })}
                        >

                            <Column field="id" header="Sr." sortable body={stakednftsdata.id} headerStyle={{ width: '7%', minWidth: '7rem' }}></Column>
                            <Column field="user" header="User" sortable body={userAddressBody} headerStyle={{ width: '14%', minWidth: '15rem' }}></Column>
                            <Column field="nft_details" header="NFT Details" sortable body={nftDetailsBody} headerStyle={{ width: '12%', minWidth: '12rem' }}></Column>
                            <Column field="rate" header="Rate" sortable body={rateBody} headerStyle={{ width: '7%', minWidth: '7rem' }}></Column>
                            <Column field="staked " header="Staked" sortable body={stakedBody} headerStyle={{ width: '6%', minWidth: '6rem' }}></Column>
                            <Column field="date" header="Datetime" sortable body={dateBody} headerStyle={{ width: '11%', minWidth: '11rem' }}></Column>
                            <Column field="status" header="Status" body={actionBodyTemplate} headerStyle={{ width: '8%', minWidth: '8rem' }}></Column>
                        </DataTable>


                    </div>
                </div>
            </div>

        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(StakedNfts, comparisonFn);