import React, { useReducer, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { decryptData, encryptData } from '../service/enc';
import copy from "copy-to-clipboard";
import Web3 from 'web3';

const init = initialState => initialState;

//OJO: action deconstruido automaticamente en type y payload
const reducer = (state, { type, payload }) => {
    switch (type) {
        case "onPage":
            return { ...state, loading: true, first: payload.first };
        case "dataLoaded":
            return { ...state, results: payload, loading: false };
        default:
            throw new Error();
    }
};

const P2P = (props) => {
    const web3 = new Web3(Web3.givenProvider || process.env.REACT_APP_RPC)

    const copyaddress = (address) => {
        copy(address);
    }

    const [P2Pdata, setP2Pdata] = useState([]);
    const [loading1, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [SearchInput, setSearchInput] = useState('');
    const [setting, setsetting] = useState([]);
    const [smdata, setsmdata] = useState([]);

    const dt = useRef(null);
    const dateconvert = (d) => {
        var dateFormat = new Date(d);
        var tedarte = dateFormat.getDate() +
            "-" + (dateFormat.getMonth() + 1) +
            "-" + dateFormat.getFullYear() +
            " " + dateFormat.getHours() +
            ":" + dateFormat.getMinutes() +
            ":" + dateFormat.getSeconds();
        return tedarte
    }
    const history = useHistory();
    var user_name = sessionStorage.getItem("user_name");

    useEffect(() => {
        if (!user_name) {
            history.push('/');
        }
    }, []);

    const initialState = {
        results: [],
        loading: false,
        first: 0,
        rows: 10,
        totalRecords: totalRows
    };
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const { loading, rows, first } = state;

    const getSetting = async () => {
        var datatosend = {
            method: "setting",
            submethod: "get",
            key: process.env.REACT_APP_KEY,
        };

        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
            data: await encryptData(datatosend)
        }).then(res => {
            var dedata = decryptData(res.data);
            if (dedata.error) {
                console.error(dedata);
                return ''
            }
            setsetting(dedata.data)
        })
    }
    const GetP2Pdata = async page => {
        if (!setting.p2p_contract) {
            return
        }
        var P2Pcontract = new web3.eth.Contract(JSON.parse(setting.p2p_contract_abi), setting.p2p_contract);
        var data = await P2Pcontract.methods.getOrders().call();
        var totalARMA = await P2Pcontract.methods.totalARMA().call();
        var totalBUSD = await P2Pcontract.methods.totalBUSD().call();
        var totalBuyAUSD = await P2Pcontract.methods.totalBuyAUSD().call();
        var totalSellAUSD = await P2Pcontract.methods.totalSellAUSD().call();

        var tARMA = Number(web3.utils.fromWei(totalARMA));
        var tBUSD = Number(web3.utils.fromWei(totalBUSD));
        var tBAUSD = Number(web3.utils.fromWei(totalBuyAUSD));
        var tSAUSD = Number(web3.utils.fromWei(totalSellAUSD));
        setsmdata({
            tARMA,
            tBUSD,
            tBAUSD,
            tSAUSD
        })
        var newArray = data.filter(function (el) {
            return el.status === "1" && el._address !== "0x0000000000000000000000000000000000000000";
        });
        // console.log("new arara", newArray);
        setP2Pdata(newArray);
        setTotalRows(newArray.length);
    }

    useEffect(() => {
        GetP2Pdata();
    }, [setting])
    useEffect(() => {
        getSetting();
    }, [])

    const userAddressBody = (rowData) => {
        return (
            <>
                <b><i class="pi pi-send" aria-hidden="true"></i> </b>
                <span><a href={`${process.env.REACT_APP_TAD_URL}${rowData._address}`} target="_blank">{rowData._address.substr(0, 5) + "..." + rowData._address.substr(-5, 5)}</a> </span><span onClick={(e) => copyaddress(rowData._address)} id={rowData._address}><i className='pi pi-copy' /></span>
                <br />
            </>
        );
    }
    const fromToken = (rowData) => {
        return (
            <span>{rowData.tokenId === '1' ? 'AUSD' : 'AARMA'}</span>
        )
    }
    const total_ausd = (rowData) => {
        var rvalue = Number(web3.utils.fromWei(rowData.receiveValue));
        return (
            <span>{rvalue.toFixed(4)} AUSD</span>
        )
    }
    const total_token = (rowData) => {
        var rate = Number(web3.utils.fromWei(rowData.rate));
        var rvalue = Number(web3.utils.fromWei(rowData.receiveValue));
        return (
            <span>{(rvalue / rate).toFixed(4)} {rowData.tokenId === '1' ? 'AUSD' : 'AARMA'}</span>
        )
    }
    const statusBody = (rowData) => {
        return (
            <>
                <span>
                    {rowData.status === '1' ? "Pending" : rowData.status === '2' ? "Completed" : "Reverted"}
                </span>
            </>
        );
    }

    const dateBody = (rowData) => {
        return (
            <>
                <span>
                    {dateconvert(rowData.timestamp * 1000)}
                </span>
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                {rowData._type === '1' ?
                    <span>
                        <span>Sell</span>
                    </span>
                    :
                    <span>
                        <span>Buy</span>
                    </span>
                }
            </>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">P2P Orders</h5>
        </div>
    );

    return (

        <>

            <title>P2P</title>

            <div className="grid TokenList-demo">

                <div className="col-12 lg:col-6 xl:col-3">
                    <div className="card mb-0">
                        <div className="flex justify-content-between mb-0">
                            <div className='mb-5'>
                                <span className="block text-500 font-medium mb-3">TOTAL AARMA</span>
                                <div className="text-900 font-medium text-xl mb-1 mt-1">
                                    {smdata.tARMA} {process.env.REACT_APP_TICKER}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 lg:col-6 xl:col-3">
                    <div className="card mb-0">
                        <div className="flex justify-content-between mb-0">
                            <div className='mb-5'>
                                <span className="block text-500 font-medium mb-3">TOTAL BUSD</span>
                                <div className="text-900 font-medium text-xl mb-1 mt-1">
                                    {smdata.tBUSD} {process.env.REACT_APP_TICKER}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 lg:col-6 xl:col-3">
                    <div className="card mb-0">
                        <div className="flex justify-content-between mb-0">
                            <div className='mb-5'>
                                <span className="block text-500 font-medium mb-3">TOTAL BUY AUSD</span>
                                <div className="text-900 font-medium text-xl mb-1 mt-1">
                                    {smdata.tBAUSD} {process.env.REACT_APP_TICKER}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 lg:col-6 xl:col-3">
                    <div className="card mb-0">
                        <div className="flex justify-content-between mb-0">
                            <div className='mb-5'>
                                <span className="block text-500 font-medium mb-3">TOTAL SELL AUSD</span>
                                <div className="text-900 font-medium text-xl mb-1 mt-1">
                                    {smdata.tSAUSD} {process.env.REACT_APP_TICKER}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card">

                        <DataTable ref={dt} value={P2Pdata} dataKey="id" paginator rows={10} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of P2P"
                            SearchInput={SearchInput} emptyMessage="No data found." header={header} responsiveLayout="scroll"
                            totalRecords={totalRows} lazy first={first} onPage={e => dispatch({ type: "onPage", payload: e })}
                        >
                            {/* <Column field="id" header="Sr." sortable body={P2Pdata.id} headerStyle={{ width: '7%', minWidth: '7rem' }}></Column> */}
                            <Column field="user" header="User" sortable body={userAddressBody} headerStyle={{ width: '14%', minWidth: '15rem' }}></Column>
                            <Column field="user" header="From Token" sortable body={fromToken} headerStyle={{ width: '8%', minWidth: '5rem' }}></Column>
                            <Column field="nft_details" header="Total Token" sortable body={total_token} headerStyle={{ width: '12%', minWidth: '8rem' }}></Column>
                            <Column field="rate" header="Total AUSD" sortable body={total_ausd} headerStyle={{ width: '7%', minWidth: '7rem' }}></Column>
                            <Column field="date" header="Datetime" sortable body={dateBody} headerStyle={{ width: '11%', minWidth: '11rem' }}></Column>
                            <Column field="status" header="Type" body={actionBodyTemplate} headerStyle={{ width: '8%', minWidth: '8rem' }}></Column>
                            <Column field="status" header="Status" body={statusBody} headerStyle={{ width: '8%', minWidth: '8rem' }}></Column>
                        </DataTable>

                    </div>
                </div>
            </div>

        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(P2P, comparisonFn);