import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import { Route, useLocation, useHistory } from 'react-router-dom';
import { decryptData, encryptData } from '../service/enc';

import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import '../assets/demo/flags/flags.css';
import '../assets/demo/Demos.scss';
import '../assets/layout/layout.scss';

const Home = () => {
    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);

    const [username, setusername] = useState('');
    const [password, setpassword] = useState('');

    const [usererror, setusererror] = useState('');
    const [passworderror, setpassworderror] = useState('');

    const history = useHistory();

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });


    const checkLogin = async (e) => {
        if ((username === '') && (password === '')) {
            setpassworderror("Enter Username and Password");
        }
        else if (username === '') {
            setusererror("Enter Username");
            setpassworderror("");
        }
        else if (password === '') {
            setpassworderror("Enter Password");
            setusererror("");
        }
        else {
            var datatosend = {
                method: "adminLogin",
                submethod: "check",
                key: process.env.REACT_APP_KEY,
                username: username,
                password: password
            }
            await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
                data: await encryptData(datatosend)
            })
                .then(res => {
                    var dedata = decryptData(res.data);
                    // console.log("tesssssstttttt", dedata)
                    if (dedata === '1') {
                        window.sessionStorage.setItem("qerW9UdE885LKHdW", "ONqUlF");
                        window.sessionStorage.setItem("user_name", username);
                        setpassworderror("");
                        history.push("/Dashboard");
                    } else {
                        setpassworderror("Username/Password is/or Incorrect");
                        setusererror("");
                        window.sessionStorage.setItem("qerW9UdE885LKHdW", "ONqUlA");
                    }
                })
        }
    }

    return (

        <>

            <title>Login</title>

            <div className="grid pdt">
                <div className="col-12 md:col-2"></div>
                <div className="col-12 md:col-5">
                    <div className="card p-fluid">
                        <h5><b>Login</b></h5>
                        <div className="field">
                            <label htmlFor="username">Username *</label>
                            <InputText id="username" type="username" onChange={(e) => setusername(e.target.value)} required />
                            <span className='text-danger'>{usererror}</span>
                        </div>
                        <div className="field">
                            <label htmlFor="password">Password *</label>
                            <InputText id="password" type="password" onChange={(e) => setpassword(e.target.value)} required />
                            <span className='text-danger'>{passworderror}</span>
                        </div>

                        <div className="field grid">
                            <div className="col-3 md:col-3">
                                <Button type="button" label="Login" onClick={checkLogin}></Button>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </>
    );
}

export default Home;
